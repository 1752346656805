<template>
<div>
  <main> 
    <NavBar :title="'AM PLATFORM'"
            :titleIcon="'mdi-percil-ruler'"
            :menuItems= menuItems
            />
    <v-container class="pt-10 mt-10" >
          <v-slide-x-transition mode="out-in">
            <router-view/>
          </v-slide-x-transition>
    </v-container>
</main>
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'

  export default {
    name: 'ManageAm',
    components: { NavBar},
    data () {   
      return {
        menuItems: [ 
          { title:'AM Platform', routeName:'AlloyDesignAdditive', routeParams:{}}
        ]
      }
    }
  }
</script>
